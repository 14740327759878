<template>
  <v-main>
    <div v-if="this.$store.state.loggedInAs.type === 'organization'">
      <CmsPage pageKey="Nav_Page_Help" />
    </div>
    <v-container v-else>
      <h1 class="display-1">Help</h1>
      <v-dialog v-model="dialogs.mapParcelLookup" max-width="800">
        <v-card class="mx-auto">
          <v-card-title
            >Truro Map-Parcel Lookup

            <v-spacer></v-spacer>
            <v-btn @click="dialogs.mapParcelLookup = false" icon><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <map-parcel-lookup></map-parcel-lookup>
          </v-card-text>
        </v-card>
      </v-dialog>
      <div class="mt-5">
        <h3 class="title">Support</h3>
        <p class="ml-6 pa-4">
          <v-btn
            class="text-capitalize mr-5"
            large
            to="/contact?type=helpRequest"
            color="primary"
            dark
            >Help Request</v-btn
          >
          <v-btn class="text-capitalize" large to="/contact?type=comment" color="primary" dark
            >Comments/Suggestion</v-btn
          >
        </p>
      </div>
      <div>
        <div>
          <h3 class="title">Features</h3>
          <p class="ml-6 pa-4">
            <about-features></about-features>
          </p>
        </div>
        <div>
          <h3 class="title">Health & Water</h3>
          <p class="ml-6 pa-4">
            <nitrate-button></nitrate-button>
          </p>
        </div>
        <div>
          <h3 class="title">Data</h3>
          <div class="ml-6 pa-4">
            <privacy-button class="mb-2 mr-5"></privacy-button>
            <swift-scan
              display="d-inline-block"
              :icon="false"
              class="mr-5 mb-2"
              doc="tax bill"
              docNumber="Real Estate Tax bill"
            >
            </swift-scan>
            <v-btn
              class="text-capitalize"
              @click="dialogs.mapParcelLookup = true"
              large
              color="primary"
              >Truro Map-Parcel Lookup</v-btn
            >
          </div>
        </div>
        <div>
          <h3 class="title">About Peeps</h3>
          <p class="ml-6 pa-4">
            <peeps-button></peeps-button>
          </p>
        </div>
        <div>
          <h3 class="title">Water Protectors Hotline</h3>
          <div class="ml-4 pa-4">
            <div v-if="auth">
              <p>
                Truro Water Protectors has volunteered to provide additional information related to
                drinking water. <br />
                Call or Text the hotline at 508-296-0007.<br />
                If you would like a free water test kit,
                <span v-if="hasParcels()">
                  navigate to your parcel and click on the water feature, and on that page you will
                  find a button to arrange for your free water test kit.
                </span>

                <span v-else>
                  simply click on the Parcels option on the top menu bar. Once you have added your
                  parcel information for the parcel you would like tested for water quality, your
                  test kit can be prepared for you. This kit is free to active Peeps users.
                </span>
              </p>
            </div>
            <div v-else>
              <p>The hotline is available to active Peeps users.</p>
            </div>
          </div>
        </div>
        <div>
          <h3 class="title">Solar Electric Assistance</h3>
          <div class="ml-4 pa-4">
            <div v-if="auth">
              <p>
                The Truro Energy Committee members are available to provide information for
                residents interested in solar panels. Simply complete the Comment form above and
                note that you are interested in solar assistance, and someone from the committee
                will contact you.
              </p>
            </div>
            <div v-else>
              <p>
                Solar assistance scheduling is available through your Peeps account for your parcel.
              </p>
            </div>
          </div>
        </div>
        <div>
          <h3 class="title">Technical</h3>
          <div class="ml-4 pa-4">
            <v-btn
              color="primary"
              dark
              large
              href="https://www.eia.gov/environment/emissions/co2_vol_mass.php"
              target="_co2Emission"
              class="text-capitalize"
              >CO2 Emissions</v-btn
            >
          </div>
        </div>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import parcel from '@/mixins/parcel';
import { mapGetters } from 'vuex';
export default {
  metaInfo: {
    title: 'Help'
  },
  mixins: [parcel],
  components: {
    MapParcelLookup: () => import('../components/MapParcelLookup.vue'),
    AboutFeatures: () => import('../components/Core/About/Features.vue'),
    SwiftScan: () => import('../components/SwiftScan.vue'),
    NitrateButton: () => import('@/components/Core/Features/NitrateButton.vue'),
    PrivacyButton: () => import('@/components/Core/Features/PrivacyButton.vue'),
    PeepsButton: () => import('@/components/Core/Features/PeepsButton.vue'),
    CmsPage: () => import('../components/cms/Page.vue')
  },
  data: () => ({
    data: '',
    search: '',
    dialogs: {
      mapParcelLookup: false
    },
    fab: false
  }),
  computed: {
    ...mapGetters(['parcels']),
    userFeatures() {
      return this.$store.getters.user.profile.features;
    },
    showFeatures() {
      if (this.$store.getters.auth) {
        if (this.$store.getters.user.profile.features) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  },
  async mounted() {
    let user = await this.currentUser();
    if (user) {
      try {
        this.getParcels(user.uid);
      } catch (error) {
        console.warn(error);
      }
    }
  },
  methods: {
    showFeature(feature) {
      if (!feature) {
        return true;
      }
      if (this.$store.getters.auth) {
        if (this.isFeatureAvailable(feature)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    hasParcels() {
      return this.parcels.length > 0;
    }
  }
};
</script>

<style scoped>
.build-date {
  bottom: 1rem;
  right: 2rem;
  position: absolute;
  color: rgb(114, 107, 107);
}
</style>
